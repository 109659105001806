
module.exports = (function(){
  var d = _eai_d;
  var r = _eai_r;
  window.emberAutoImportDynamic = function(specifier) {
    if (arguments.length === 1) {
      return r('_eai_dyn_' + specifier);
    } else {
      return r('_eai_dynt_' + specifier)(Array.prototype.slice.call(arguments, 1))
    }
  };
  window.emberAutoImportSync = function(specifier) {
    return r('_eai_sync_' + specifier)(Array.prototype.slice.call(arguments, 1))
  };
    d('@azure/msal-browser', EAI_DISCOVERED_EXTERNALS('@azure/msal-browser'), function() { return require('@azure/msal-browser'); });
    d('@okta/okta-auth-js', EAI_DISCOVERED_EXTERNALS('@okta/okta-auth-js'), function() { return require('@okta/okta-auth-js'); });
    d('@popperjs/core', EAI_DISCOVERED_EXTERNALS('@popperjs/core'), function() { return require('@popperjs/core'); });
    d('@sentry/browser', EAI_DISCOVERED_EXTERNALS('@sentry/browser'), function() { return require('@sentry/browser'); });
    d('@sentry/utils', EAI_DISCOVERED_EXTERNALS('@sentry/utils'), function() { return require('@sentry/utils'); });
    d('ember-cookies/services/cookies', EAI_DISCOVERED_EXTERNALS('ember-cookies/services/cookies'), function() { return require('ember-cookies/services/cookies'); });
    d('ember-focus-trap/modifiers/focus-trap.js', EAI_DISCOVERED_EXTERNALS('ember-focus-trap/modifiers/focus-trap.js'), function() { return require('ember-focus-trap/modifiers/focus-trap.js'); });
    d('ember-router-helpers/helpers/is-active.js', EAI_DISCOVERED_EXTERNALS('ember-router-helpers/helpers/is-active.js'), function() { return require('ember-router-helpers/helpers/is-active.js'); });
    d('ember-router-helpers/helpers/replace-with.js', EAI_DISCOVERED_EXTERNALS('ember-router-helpers/helpers/replace-with.js'), function() { return require('ember-router-helpers/helpers/replace-with.js'); });
    d('ember-router-helpers/helpers/root-url.js', EAI_DISCOVERED_EXTERNALS('ember-router-helpers/helpers/root-url.js'), function() { return require('ember-router-helpers/helpers/root-url.js'); });
    d('ember-router-helpers/helpers/route-params.js', EAI_DISCOVERED_EXTERNALS('ember-router-helpers/helpers/route-params.js'), function() { return require('ember-router-helpers/helpers/route-params.js'); });
    d('ember-router-helpers/helpers/transition-to.js', EAI_DISCOVERED_EXTERNALS('ember-router-helpers/helpers/transition-to.js'), function() { return require('ember-router-helpers/helpers/transition-to.js'); });
    d('ember-router-helpers/helpers/url-for.js', EAI_DISCOVERED_EXTERNALS('ember-router-helpers/helpers/url-for.js'), function() { return require('ember-router-helpers/helpers/url-for.js'); });
    d('fast-memoize', EAI_DISCOVERED_EXTERNALS('fast-memoize'), function() { return require('fast-memoize'); });
    d('intl-messageformat', EAI_DISCOVERED_EXTERNALS('intl-messageformat'), function() { return require('intl-messageformat'); });
    d('intl-messageformat-parser', EAI_DISCOVERED_EXTERNALS('intl-messageformat-parser'), function() { return require('intl-messageformat-parser'); });
    d('js-cookie', EAI_DISCOVERED_EXTERNALS('js-cookie'), function() { return require('js-cookie'); });
    d('jwt-decode', EAI_DISCOVERED_EXTERNALS('jwt-decode'), function() { return require('jwt-decode'); });
    d('keycloak-js', EAI_DISCOVERED_EXTERNALS('keycloak-js'), function() { return require('keycloak-js'); });
    d('rsvp', EAI_DISCOVERED_EXTERNALS('rsvp'), function() { return require('rsvp'); });
    d('validated-changeset', EAI_DISCOVERED_EXTERNALS('validated-changeset'), function() { return require('validated-changeset'); });
    d('_eai_dyn_@sentry/browser', [], function() { return import('@sentry/browser'); });
})();
